<!-- Author: Wilson Pilco Nunez -->
<!-- Email: wilsonaux1@gmail.com -->
<!-- Created at: 2024-09-27 16:00 -->
<!-- Description:  -->
<template>
  <div>
    <table class="table table-sm table-bordered">
      <tr>
        <th>Cliente:</th>
        <td>{{ quote.client_name }}</td>
      </tr>
      <tr>
        <th>Fecha:</th>
        <td>{{ quote.ini_date | dateTimeFormat }}</td>
      </tr>
    </table>
    <div class="mb-3">
      <app-checkbox v-model="mark_maked">Marcar como realizado</app-checkbox>
    </div>
    <QuoteFormTreatmentsTable ref="elQuoteFormTreatmentsTable">
    </QuoteFormTreatmentsTable>
    <div>
      <button @click="save(1)" :disabled="sending" class="btn btn-success mr-1">
        Guardar
      </button>
      <button @click="save(2)" :disabled="sending" class="btn btn-success mr-1">
        Guardar y Revisar
      </button>
    </div>
  </div>
</template>
<script>
import QuoteFormTreatmentsTable from "./QuoteFormTreatmentsTable.vue";
import DentalClinicService from "../DentalClinicService.js";

export default {
  name: "QuoteTreatmentsForm",
  components: {
    QuoteFormTreatmentsTable,
  },

  // directives

  props: {
    quote: {},
    disabled: { default: false },
  },

  data: () => ({
    sending: false,
    mark_maked: true,
  }),

  computed: {
    // errors() { return false; }
  },

  // watch: {},

  mounted() {
    //
  },

  methods: {
    async save(method) {
      // guardar en backend
      this.sending = true;
      let treatmentPlanId = null;
      if(this.mark_maked) {
        await DentalClinicService.saveQuote({
          id: this.quote.id,
          update_status: 1,
          status_id: 5,
        });
        // se requiere actualizar la lista de citas mas adelante
      }
      let res = await DentalClinicService.saveTreatmentPlan({
        quote_id: this.quote.id,
        treatments: this.$refs.elQuoteFormTreatmentsTable.getValues(),
        employee_id: this.quote.employee_id,
        client_id: this.quote.client_id,
        mark_maked: this.mark_maked ? 1 : undefined,
      });
      treatmentPlanId = res.id;
      if(method == 2) {
        this.$router.push(
          `/dental-clinic/patients/${this.quote.client_id}/treatment-plans/${treatmentPlanId}`
        )
      } else {
        this.$emit("saved");
      }
      this.sending = false;
    },
    reset() { 
      this.sending = false;
      this.mark_maked = true;
    },
    setValue() {},
  }
};
</script>
<style scoped></style>

