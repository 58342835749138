<template>
  <div>
    <div class="row">
      <div class="col-lg-2">
        <div>
          <button
            class="btn btn-success mb-1"
            @click.prevent="
              $refs.dNewCli.show();
              $refs.formCl.reset();
            "
          >
            <i class="fa fa-user-plus"></i> Nuevo Paciente
          </button>
          <button
            type="button"
            class="btn btn-success dropdown-toggle"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i class="fa fa-file-o"></i>
            Crear Proforma
          </button>
          <menu-proform-links></menu-proform-links>
        </div>
        <app-daypicker
          class="my-3"
          v-model="date"
          @input="changeDate($event)"
        ></app-daypicker>
        <SelectEmployee
          :specialties="config_treatments.specialty_id"
          v-model="employee_id"
          placeholder="Todos los dentistas"
        ></SelectEmployee>
        <div>
          <m-select-status
            @input="states = $event"
            ref="ss"
            @mounted="$refs.ss.emitValues()"
            class="mt-3 app_m_select_status"
          >
            <div class="d-flex mb-2">
              <div class="mr-2">&nbsp;</div>
              <app-checkbox v-model="noHaveNextQuote">
                Sin proxima cita
              </app-checkbox>
            </div>
          </m-select-status>
        </div>
      </div>
      <div class="col-lg-10">
        <app-input-search class="mb-2" v-model="search"></app-input-search>
        <DoctorTab
          v-if="config_treatments.show_doctor_tab"
          :employee_id="employee_id"
          :specialties="config_treatments.specialty_id"
          @change="employee_id = $event"
        ></DoctorTab>
        <div>
          <table class="table table-sm bg-white ">
            <tbody>
              <template v-for="l in filterList">
                <tr :key="l.id">
                  <td>
                    <div
                      v-quote-bg-color="l.status_id"
                      class="mr-2 p-2 rounded text-center"
                    >
                      <div>{{ l.ini_date | timeFormat }}</div>
                      <span class="fa fa-arrow-down"></span>
                      <div>{{ l.end_date | timeFormat }}</div>
                    </div>
                  </td>
                  <td>
                    <div>
                      <div class="dropdown">
                        <a
                          href="#" data-toggle="dropdown"
                          class="dropdown-toggle">
                          <i
                            v-show="l.group == 2"
                            class="fa fa-bolt pull-right app_td_q_light mr-1"
                            data-session-id="43606"
                          ></i>
                          <strong class=" mr-1" v-show="l.client_code">
                            {{ l.client_code }}
                          </strong>
                          <b class="text-wrap" :title="l.id">
                            {{ l.client_name }}
                          </b>
                        </a>

                        <div class="dropdown-menu">
                          <a
                            href="javascript:void(0)"
                            @click.prevent="
                              $refs.elModalQuoteTreatments.show();
                              $refs.elQuoteTreatmentForm.reset();
                              quoteSelected = l;
                            "
                            class="dropdown-item"
                          >
                            Registrar Tratamiento
                          </a>
                          <router-link
                            v-show="l.treatment_plan_id"
                            :to="
                              `/dental-clinic/patients/${l.client_id}/treatment-plans/${l.treatment_plan_id}`
                            "
                            class="dropdown-item"
                          >
                            Plan de tratamiento
                          </router-link>
                          <router-link
                            :to="
                              `/dental-clinic/patients/${l.client_id}/to-pay`
                            "
                            class="dropdown-item"
                          >
                            Recaudacion
                          </router-link>
                          <router-link
                            :to="
                              `/dental-clinic/patients/${l.client_id}/profile`
                            "
                            class="dropdown-item"
                          >
                            Datos personales
                          </router-link>
                          <div class="dropdown-divider"></div>
                          <a
                            href="javascript:void(0)"
                            @click.prevent="
                              $refs.elChangeDate.load(l);
                              $refs.elModalFormQuote.show();
                            "
                            class="dropdown-item"
                            :class="{
                              disabled: !hasPermission(
                                'srv-quotes-change-status'
                              )
                            }"
                          >
                            Cambio de Fecha
                          </a>
                          <a
                            href="javascript:void(0)"
                            @click.prevent="
                              $refs[`com${l.id}`][0].letsComment()
                            "
                            class="dropdown-item"
                          >
                            Agregar comentario
                          </a>
                        </div>
                      </div>
                    </div>
                    <div class="text-muted">
                      <app-span-phonenumber
                        :number="l.client_phone_number"
                      ></app-span-phonenumber>
                    </div>
                    <div>
                      <i class="fas fa-user-md mr-1"></i>
                      <span class="mr-1">{{ l.employee_name }}</span>
                      <quote-comment
                        @statusChanged="l.status_id = $event"
                        :curr_status="l.status_id"
                        :quote_id="l.id"
                        :comment="l.commentary"
                        :ref="'com' + l.id"
                      ></quote-comment>
                    </div>
                  </td>
                  <td></td>
                  <td v-show="!$store.state.display.sm">
                    <select-status
                      class="mb-1 d-block"
                      :value="l.status_id"
                      :lastChangeTime="l.updated_at"
                      @input="updateStatusQuote(l, $event)"
                      :disabled="!hasPermission('srv-quotes-change-status')"
                    ></select-status>
                    <app-wsp-button
                      v-if="showWspButton"
                      class="btn-sm"
                      :number="l.wsp_number"
                      :message="l.wsp_message"
                      >&nbsp;Mensajear</app-wsp-button
                    >
                    <div v-if="l.next_quote_date">
                      <span class="badge badge-primary">
                        Proxima cita {{ l.next_quote_date | date }}
                      </span>
                    </div>
                  </td>
                  <td class="text-center">
                    <planStatus :planStatus="l.plan_status"></planStatus>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <ButtonNew
      @newClient="
        $refs.dNewCli.show();
        $refs.formCl.reset();
      "
    ></ButtonNew>

    <app-modal-basic ref="dNewCli" ktr="dNewCli" :lg="true">
      <form-client ref="formCl" @submitted="$refs.dNewCli.hide()"></form-client>
    </app-modal-basic>

    <app-modal-basic ref="elModalFormQuote" ktr="fmq" :xl="true">
      <change-date
        ref="elChangeDate"
        @submitted="
          $refs.elModalFormQuote.hide();
          getList();
        "
      >
      </change-date>
    </app-modal-basic>

    <app-modal-basic ref="elModalQuoteTreatments">
      <QuoteTreatmentsForm
        ref="elQuoteTreatmentForm"
        :quote="quoteSelected"
        @saved="
          $refs.elModalQuoteTreatments.hide();
          getList();"
      ></QuoteTreatmentsForm>
    </app-modal-basic>
  </div>
</template>
<script>
import formClient from "../../store-module/clients/Form";
import menuProformLinks from "../proforms/MenuLinks";
import { SelectEmployee } from "../../admin-module/index.js";
import MSelectStatus from "../quotes/MSelectStatus";
import { quoteBgColor } from "../quotes/quote-bg-color-directive";
import { mapState } from "vuex";
import selectStatus from "../../service-module/quotes/SelectStatus";
import quoteComment from "../quotes/Comments.vue";
import { DentalClinicService } from "../service";
import changeDate from "../quotes/change-date";
import {
  myMoment,
  momentDateFormat,
  momentDateTimeFormat
} from "../../utils/myMoment";
import { routeMix } from "../../base-module";
import planStatus from "./PlanStatus";
import ButtonNew from "./ButtonNew";
import DoctorTab from "./DoctorTab.vue";
import hasPermissionMixin from "src/lt/utils/hasPermissionMixin.js";
import QuoteTreatmentsForm from "../quotes/QuoteTreatmentsForm.vue";
export default {
  mixins: [routeMix, hasPermissionMixin],

  components: {
    menuProformLinks,
    formClient,
    SelectEmployee,
    MSelectStatus,
    selectStatus,
    quoteComment,
    changeDate,
    planStatus,
    ButtonNew,
    DoctorTab,
    QuoteTreatmentsForm
  },

  directives: {
    quoteBgColor
  },

  // filters

  props: {
    //
  },

  data: () => ({
    date: new Date(),
    list: [],
    states: {},
    employee_id: null,
    search: "",
    noHaveNextQuote: false,
    quoteSelected: {},
  }),

  computed: {
    showWspButton() {
      return this.$store.state.config.dental.agenda.show_wsp_button;
    },
    filterList() {
      let list = this.list.filter((x) => {
        if (!this.states[x.status_id]) {
          return false;
        }
        if (
          this.search.length > 0 &&
          x.client_name.toLowerCase().indexOf(this.search) == -1
        ) {
          return false;
        }
        if (this.employee_id && this.employee_id != x.employee_id) {
          return false;
        }
        if (this.noHaveNextQuote && x.next_quote_id) {
          return false;
        }

        return true;
      });
      this.$store.commit("dental/setQuotes", list.length);
      return list;
    },
    ...mapState({
      config_treatments: (s) => s.config.dental.treatments
    })
  },

  watch: {
    $route: {
      immediate: true,
      handler(to) {
        let dateRoute = myMoment(to.query.date, momentDateFormat);
        this.date = dateRoute.isValid()
          ? dateRoute.toDate()
          : myMoment().toDate();
        this.getList();
      }
    }
  },

  mounted() {
    //
  },

  methods: {
    changeDate(newDate) {
      this.routeMixAddQueryParam(
        "date",
        myMoment(newDate).format(momentDateFormat)
      );
    },
    updateStatusQuote(quote, status_id) {
      quote.status_id = status_id;
      DentalClinicService.saveQuote({
        id: quote.id,
        update_status: 1,
        status_id
      }).then(() => {
        quote.updated_at = myMoment().format(momentDateTimeFormat);
      });
    },
    getList() {
      DentalClinicService.getQuotes({
        date: myMoment(this.date).format(momentDateFormat),
        employee_id: this.employee_id,
        per_page: 10000,
        add_debts: 1
      }).then((res) => {
        var list = res.data;
        this.$store.commit("dental/setQuotes", list.length);
        this.list = list.map((x) => {
          let iniDate = myMoment(x.ini_date);
          x.wsp_number = "51" + x.client_phone_number;
          x.wsp_message = `Hola ${
            x.client_name
          }, hoy tienes una cita programada a las ${iniDate.format(
            "hh:mma"
          )}`;
          return x;
        });
      });
    },
    addCountryCode(number) {
      if (number.length > 0) {
        return "51" + number;
      }
      return "";
    }
    // makeMessage(quote) {
    //   // var ini_date = quote.ini_date;
    //   // var hour, date;
    //   return `Hola ${quote.client_name} tienes una cita a las ${hour} el dia ${date}.`
    // }
  }
};
</script>

<style>
.app_m_select_status {
  font-size: 80%;
}

.app_td_q_light {
  margin-right: 0px;
  color: #fff;
  font-size: 12px;
  left: -40px;
  top: -5px;
  background: rgb(255, 112, 0);
  padding: 2px 2px;
  border-radius: 4px;
}
</style>
